import { useState } from "react";
import Subclass189 from "./Subclass189";
import Subclass190 from "./Subclass190";
import Subclass489 from "./Subclass489";
import Subclass491 from "./Subclass491";

const SubClass = () => {
  const [subClasses, setSubClasses] = useState("");

  const open189 = () => {
    setSubClasses("189");
  };
  const open190 = () => {
    setSubClasses("190");
  };
  const open489 = () => {
    setSubClasses("489");
  };
  const open491 = () => {
    setSubClasses("491");
  };
  return (
    <>
      <div
        className={`transition-all ease-in-out duration-300 container mx-auto px-8 flex flex-col align-center justify-center max-w-[1080px] ${
          subClasses === "" ? "h-[75vh]" : "h-[60vh]"
        }`}
      >
        <h1 className="text-2xl flex flex-col gap-4 text-center font-bold my-8">
          <span>Calculate your points for Permanent Residency application</span>
          <span className="text-xl font-normal">
            Select a visa subclass from following options.
          </span>
        </h1>
        <div className="mb-4 md:grid md:grid-cols-2 flex flex-col gap-4 items-center justify-center">
          <button
            className="flex items-center justify-center"
            onClick={open189}
          >
            <p
              className={`transition-all ease-in-out duration-300 border p-2 rounded-lg ${
                subClasses === "189" ? "bg-[#3b82f6]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[22rem]`}
            >
              Skilled Independent Visa (Subclass 189)
            </p>
          </button>
          <button
            className="flex items-center justify-center"
            onClick={open190}
          >
            <p
              className={`transition-all ease-in-out duration-300 border p-2 rounded-lg ${
                subClasses === "190" ? "bg-[#3b82f6]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[22rem]`}
            >
              Skilled Nominated Visa (Subclass 190)
            </p>
          </button>
          <button
            className="flex items-center justify-center"
            onClick={open491}
          >
            <p
              className={`transition-all ease-in-out duration-300 border p-2 rounded-lg ${
                subClasses === "491" ? "bg-[#3b82f6]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[22rem]`}
            >
              Skilled Work Regional Visa (Subclass 491)
            </p>
          </button>
          <button
            className="flex items-center justify-center"
            onClick={open489}
          >
            <p
              className={`transition-all ease-in-out duration-300 border p-2 rounded-lg ${
                subClasses === "489" ? "bg-[#3b82f6]" : "bg-[#858585]"
              } text-center text-white mb-2 w-[22rem]`}
            >
              Skilled Regional Visa (Subclass 489)
            </p>
          </button>
        </div>
      </div>
      {subClasses === "189" ? (
        <Subclass189 />
      ) : subClasses === "190" ? (
        <Subclass190 />
      ) : subClasses === "489" ? (
        <Subclass489 />
      ) : subClasses === "491" ? (
        <Subclass491 />
      ) : (
        ""
      )}
    </>
  );
};

export default SubClass;
