import { useState, useRef, useEffect } from "react";

import {
  Age,
  AusStudy,
  AustralianEmployment,
  Education,
  EnglishLanguage,
  Naati,
  OverseasEmployment,
  PartnerSkills,
  ProfYear,
  RegionalStudy,
  SpecEdu,
  Sponsor491,
  Result491,
} from "../../components/PointsTable";

const SubClass491 = () => {
  const [pointsAge, setPointsAge] = useState(0);
  const [pointsEng, setPointsEng] = useState(0);
  const [pointsOVemp, setPointsOVemp] = useState(0);
  const [pointsAUemp, setPointsAUemp] = useState(0);
  const [pointsEdu, setPointsEdu] = useState(0);
  const [pointsSepcEdu, setPointsSpecEdu] = useState(0);
  const [pointsAusStudy, setPointsAusStudy] = useState(0);
  const [pointsRegStudy, setPointsRegStudy] = useState(0);
  const [pointsProfYear, setPointsProfYear] = useState(0);
  const [pointsNaati, setPointsNaati] = useState(0);
  const [pointsPartner, setPointsPartner] = useState(0);
  const [pointsSponsor491, setPointsSponsor491] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);
  const firstInnerDivRef = useRef(null);
  const secondInnerDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (firstInnerDivRef.current && secondInnerDivRef.current) {
      const topDivHeight =
        firstInnerDivRef.current.getBoundingClientRect().height;
      if (scrollPosition > topDivHeight - 150) {
        secondInnerDivRef.current.style.position = "absolute";
        secondInnerDivRef.current.style.bottom = "-6.5rem";
        secondInnerDivRef.current.style.width = "100%";
      } else {
        secondInnerDivRef.current.style.position = "fixed";
        secondInnerDivRef.current.style.bottom = "1rem";
      }
    }
  }, [scrollPosition]);

  return (
    <div className="relative">
      <div
        ref={firstInnerDivRef}
        className="flex flex-col items-center justify-center gap-10 mt-4 mb-[8rem]"
      >
        <h1 className="text-2xl -mb-8">
          {" "}
          Skilled Work Regional Visa{" "}
          <span className="font-bold">(Subclass 491)</span>
        </h1>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <Age setPointsAge={setPointsAge} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <EnglishLanguage setPointsEng={setPointsEng} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <OverseasEmployment setPointsOVemp={setPointsOVemp} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <AustralianEmployment setPointsAUemp={setPointsAUemp} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <Education setPointsEdu={setPointsEdu} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <SpecEdu setPointsSpecEdu={setPointsSpecEdu} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <AusStudy setPointsAusStudy={setPointsAusStudy} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <RegionalStudy setPointsRegStudy={setPointsRegStudy} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <ProfYear setPointsProfYear={setPointsProfYear} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <Naati setPointsNaati={setPointsNaati} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300">
          <PartnerSkills setPointsPartner={setPointsPartner} />
        </section>
        <section className="min-w-[320px] w-[90vw] max-w-[768px] p-4 rounded-lg shadow-md shadow-gray-300 mb-4">
          <Sponsor491 setPointsSponsor491={setPointsSponsor491} />
        </section>
      </div>
      <div
        ref={secondInnerDivRef}
        className="flex items-center fixed bottom-4 justify-center w-full"
      >
        <Result491
          pointsAge={pointsAge}
          pointsEng={pointsEng}
          pointsOVemp={pointsOVemp}
          pointsAUemp={pointsAUemp}
          pointsEdu={pointsEdu}
          pointsSepcEdu={pointsSepcEdu}
          pointsAusStudy={pointsAusStudy}
          pointsRegStudy={pointsRegStudy}
          pointsProfYear={pointsProfYear}
          pointsNaati={pointsNaati}
          pointsPartner={pointsPartner}
          pointsSponsor491={pointsSponsor491}
        />
      </div>
    </div>
  );
};

export default SubClass491;
