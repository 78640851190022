import { useState, useEffect } from "react";

export const Age = ({ setPointsAge }) => {
  const title = "Select your age band";
  const option1 = "At least 18 but less than 25 years";
  const option2 = "At least 25 but less than 33 years";
  const option3 = "At least 33 but less than 40 years";
  const option4 = "At least 40 but less than 45 years";

  const [checkAge1825, setCheckAge1825] = useState(false);
  const [checkAge2533, setCheckAge2533] = useState(false);
  const [checkAge3340, setCheckAge3340] = useState(false);
  const [checkAge4045, setCheckAge4045] = useState(false);

  const [selected, setSelected] = useState(null);

  const handleChange = (option) => {
    setSelected(option);
  };

  useEffect(() => {
    const isOptionSelected = (option) => selected === option;
    if (isOptionSelected(option1)) {
      setCheckAge1825(true);
      setCheckAge2533(false);
      setCheckAge3340(false);
      setCheckAge4045(false);
    }
    if (isOptionSelected(option2)) {
      setCheckAge1825(false);
      setCheckAge2533(true);
      setCheckAge3340(false);
      setCheckAge4045(false);
    }
    if (isOptionSelected(option3)) {
      setCheckAge1825(false);
      setCheckAge2533(false);
      setCheckAge3340(true);
      setCheckAge4045(false);
    }
    if (isOptionSelected(option4)) {
      setCheckAge1825(false);
      setCheckAge2533(false);
      setCheckAge3340(false);
      setCheckAge4045(true);
    }

    if (checkAge1825) {
      setPointsAge(25);
    }

    if (checkAge2533) {
      setPointsAge(30);
    }

    if (checkAge3340) {
      setPointsAge(25);
    }

    if (checkAge4045) {
      setPointsAge(15);
    }
  }, [
    checkAge1825,
    checkAge2533,
    checkAge3340,
    checkAge4045,
    selected,
    setPointsAge,
  ]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Age</h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option1}
            onChange={() => handleChange(option1)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option2}
            onChange={() => handleChange(option2)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option3}
            onChange={() => handleChange(option3)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option4}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option4}
            onChange={() => handleChange(option4)}
          />
        </label>
      </div>
    </div>
  );
};

export const EnglishLanguage = ({ setPointsEng }) => {
  const title = "Select your English language ability";
  const option1 = "Competent English";
  const option2 = "Proficient English";
  const option3 = "Superior English";

  const [selected, setSelected] = useState(null); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };

  const [competentEng, setCompetentEng] = useState(false);
  const [proficientEng, setProficientEng] = useState(false);
  const [superiorEng, setSuperiorEng] = useState(false);

  useEffect(() => {
    const isOptionSelected = (option) => selected === option;
    if (isOptionSelected(option1)) {
      setCompetentEng(true);
      setProficientEng(false);
      setSuperiorEng(false);
    }
    if (isOptionSelected(option2)) {
      setCompetentEng(false);
      setProficientEng(true);
      setSuperiorEng(false);
    }
    if (isOptionSelected(option3)) {
      setCompetentEng(false);
      setProficientEng(false);
      setSuperiorEng(true);
    }

    if (competentEng) {
      setPointsEng(0);
    }
    if (proficientEng) {
      setPointsEng(10);
    }
    if (superiorEng) {
      setPointsEng(20);
    }
  }, [competentEng, proficientEng, selected, setPointsEng, superiorEng]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">English language skills</h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option1}
            onChange={() => handleChange(option1)}
          />
        </label>
        <p className="ml-2 text-gray-400 text-sm mb-2">
          At least 6 in IELTS (50 in PTE) or equivalent in other approved tests.
        </p>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option2}
            onChange={() => handleChange(option2)}
          />
        </label>
        <p className="ml-2 text-gray-400 text-sm mb-2">
          At least 7 in IELTS (65 in PTE) or equivalent in other approved tests.
        </p>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option3}
            onChange={() => handleChange(option3)}
          />
        </label>
        <p className="ml-2 text-gray-400 text-sm mb-2">
          At least 8 in IELTS (79 in PTE) or equivalent in other approved tests.
        </p>
      </div>
    </div>
  );
};

export const OverseasEmployment = ({ setPointsOVemp }) => {
  const title = "Select your work experience outside Australia";
  const option1 = "Less than 3 years";
  const option2 = "At least 3 but less than 5 years";
  const option3 = "At least 5 but less than 8 years";
  const option4 = "At least 8 years";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };

  const [checkOVemp03, setCheckOVemp03] = useState(false);
  const [checkOVemp35, setCheckOVemp35] = useState(false);
  const [checkOVemp58, setCheckOVemp58] = useState(false);
  const [checkOVemp80, setCheckOVemp80] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckOVemp03(true);
      setCheckOVemp35(false);
      setCheckOVemp58(false);
      setCheckOVemp80(false);
    }
    if (selected === option2) {
      setCheckOVemp03(false);
      setCheckOVemp35(true);
      setCheckOVemp58(false);
      setCheckOVemp80(false);
    }
    if (selected === option3) {
      setCheckOVemp03(false);
      setCheckOVemp35(false);
      setCheckOVemp58(true);
      setCheckOVemp80(false);
    }
    if (selected === option4) {
      setCheckOVemp03(false);
      setCheckOVemp35(false);
      setCheckOVemp58(false);
      setCheckOVemp80(true);
    }

    if (checkOVemp03) {
      setPointsOVemp(0);
    }
    if (checkOVemp35) {
      setPointsOVemp(5);
    }
    if (checkOVemp58) {
      setPointsOVemp(10);
    }
    if (checkOVemp80) {
      setPointsOVemp(15);
    }
  }, [
    checkOVemp03,
    checkOVemp35,
    checkOVemp58,
    checkOVemp80,
    selected,
    setPointsOVemp,
  ]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">
        Overseas skilled employment (Outside Australia)
      </h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option1}
            onChange={() => handleChange(option1)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option2}
            onChange={() => handleChange(option2)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option3}
            onChange={() => handleChange(option3)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option4}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option4}
            onChange={() => handleChange(option4)}
          />
        </label>
      </div>
    </div>
  );
};

export const AustralianEmployment = ({ setPointsAUemp }) => {
  const title = "Select your work experience inside Australia";
  const option1 = "Less than 1 year";
  const option2 = "At least 1 but less than 3 years";
  const option3 = "At least 3 but less than 5 years";
  const option4 = "At least 5 but less than 8 years";
  const option5 = "At least 8 years";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };

  const [checkAUemp01, setCheckAUemp01] = useState(false);
  const [checkAUemp13, setCheckAUemp13] = useState(false);
  const [checkAUemp35, setCheckAUemp35] = useState(false);
  const [checkAUemp58, setCheckAUemp58] = useState(false);
  const [checkAUemp80, setCheckAUemp80] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckAUemp01(true);
      setCheckAUemp13(false);
      setCheckAUemp35(false);
      setCheckAUemp58(false);
      setCheckAUemp80(false);
    }
    if (selected === option2) {
      setCheckAUemp01(false);
      setCheckAUemp13(true);
      setCheckAUemp35(false);
      setCheckAUemp58(false);
      setCheckAUemp80(false);
    }
    if (selected === option3) {
      setCheckAUemp01(false);
      setCheckAUemp13(false);
      setCheckAUemp35(true);
      setCheckAUemp58(false);
      setCheckAUemp80(false);
    }
    if (selected === option4) {
      setCheckAUemp01(false);
      setCheckAUemp13(false);
      setCheckAUemp35(false);
      setCheckAUemp58(true);
      setCheckAUemp80(false);
    }
    if (selected === option5) {
      setCheckAUemp01(false);
      setCheckAUemp13(false);
      setCheckAUemp35(false);
      setCheckAUemp58(false);
      setCheckAUemp80(true);
    }

    if (checkAUemp01) {
      setPointsAUemp(0);
    }
    if (checkAUemp13) {
      setPointsAUemp(5);
    }
    if (checkAUemp35) {
      setPointsAUemp(10);
    }
    if (checkAUemp58) {
      setPointsAUemp(15);
    }
    if (checkAUemp80) {
      setPointsAUemp(20);
    }
  }, [
    checkAUemp01,
    checkAUemp13,
    checkAUemp35,
    checkAUemp58,
    checkAUemp80,
    selected,
    setPointsAUemp,
  ]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">
        Australian skilled employment (Inside Australia)
      </h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option1}
            onChange={() => handleChange(option1)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option2}
            onChange={() => handleChange(option2)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option3}
            onChange={() => handleChange(option3)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option4}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option4}
            onChange={() => handleChange(option4)}
          />
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option5}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-500"
            checked={selected === option5}
            onChange={() => handleChange(option5)}
          />
        </label>
      </div>
    </div>
  );
};

export const Education = ({ setPointsEdu }) => {
  const title = "Select your educational qualification";
  const option1 =
    "A Doctorate from an Australian educational institution or a Doctorate from another educational institution, that is of a recognised standard.";
  const option2 =
    "At least a Bachelor degree from an Australian educational institution or at least a Bachelor qualification, from another educational institution, that is of a recognised standard.";
  const option3 =
    "A diploma or trade qualification from an Australian educational institution.";
  const option4 =
    "Attained a qualification or award recognised by the relevant assessing authority for your nominated skilled occupation as being suitable for that occupation";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };

  const [checkEduDoc, setCheckEduDoc] = useState(false);
  const [checkEduBach, setCheckEduBach] = useState(false);
  const [checkEduDpl, setCheckEduDpl] = useState(false);
  const [checkEduRel, setCheckEduRel] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckEduDoc(true);
      setCheckEduBach(false);
      setCheckEduDpl(false);
      setCheckEduRel(false);
    }
    if (selected === option2) {
      setCheckEduDoc(false);
      setCheckEduBach(true);
      setCheckEduDpl(false);
      setCheckEduRel(false);
    }
    if (selected === option3) {
      setCheckEduDoc(false);
      setCheckEduBach(false);
      setCheckEduDpl(true);
      setCheckEduRel(false);
    }
    if (selected === option4) {
      setCheckEduDoc(false);
      setCheckEduBach(false);
      setCheckEduDpl(false);
      setCheckEduRel(true);
    }

    if (checkEduDoc) {
      setPointsEdu(20);
    }
    if (checkEduBach) {
      setPointsEdu(15);
    }
    if (checkEduDpl) {
      setPointsEdu(10);
    }
    if (checkEduRel) {
      setPointsEdu(10);
    }
  }, [
    checkEduBach,
    checkEduDoc,
    checkEduDpl,
    checkEduRel,
    selected,
    setPointsEdu,
  ]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Educational qualifications</h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option3}
              onChange={() => handleChange(option3)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option4}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option4}
              onChange={() => handleChange(option4)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const SpecEdu = ({ setPointsSpecEdu }) => {
  const title =
    "Do you have a Masters degree by research or a Doctorate degree from an Australian educational institution that included at least 2 academic years to in a relevant field?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkSpecEduYes, setCheckSpecEduYes] = useState(false);
  const [checkSpecEduNo, setCheckSpecEduNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckSpecEduYes(true);
      setCheckSpecEduNo(false);
    }
    if (selected === option2) {
      setCheckSpecEduYes(false);
      setCheckSpecEduNo(true);
    }

    if (checkSpecEduYes) {
      setPointsSpecEdu(10);
    }
    if (checkSpecEduNo) {
      setPointsSpecEdu(0);
    }
  }, [checkSpecEduNo, checkSpecEduYes, selected, setPointsSpecEdu]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">
        Specialist education qualification
      </h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const AusStudy = ({ setPointsAusStudy }) => {
  const title =
    "Do you have at least 1 degree, diploma or trade qualification from an Australian educational institution that meets the Australian study requirement?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkAusStudyYes, setCheckAusStudyYes] = useState(false);
  const [checkAusStudyNo, setCheckAusStudyNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckAusStudyYes(true);
      setCheckAusStudyNo(false);
    }
    if (selected === option2) {
      setCheckAusStudyYes(false);
      setCheckAusStudyNo(true);
    }

    if (checkAusStudyYes) {
      setPointsAusStudy(5);
    }
    if (checkAusStudyNo) {
      setPointsAusStudy(0);
    }
  }, [checkAusStudyNo, checkAusStudyYes, selected, setPointsAusStudy]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Australian study requirement</h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const RegionalStudy = ({ setPointsRegStudy }) => {
  const title =
    "Do you have at least 1 degree, diploma or trade qualification from an Australian educational institution that satisfies the Australian study requirement obtained while living and studying in an eligible area of regional Australia?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkRegionalStudyYes, setCheckRegionalStudyYes] = useState(false);
  const [checkRegionalStudyNo, setCheckRegionalStudyNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckRegionalStudyYes(true);
      setCheckRegionalStudyNo(false);
    }
    if (selected === option2) {
      setCheckRegionalStudyYes(false);
      setCheckRegionalStudyNo(true);
    }

    if (checkRegionalStudyYes) {
      setPointsRegStudy(5);
    }
    if (checkRegionalStudyNo) {
      setPointsRegStudy(0);
    }
  }, [
    checkRegionalStudyNo,
    checkRegionalStudyYes,
    selected,
    setPointsRegStudy,
  ]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Study in regional Australia</h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const ProfYear = ({ setPointsProfYear }) => {
  const title = "Did you complete a professional year in Australia?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkProfYearYes, setCheckProfYearYes] = useState(false);
  const [checkProfYearNo, setCheckProfYearNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckProfYearYes(true);
      setCheckProfYearNo(false);
    }
    if (selected === option2) {
      setCheckProfYearYes(false);
      setCheckProfYearNo(true);
    }

    if (checkProfYearYes) {
      setPointsProfYear(5);
    }
    if (checkProfYearNo) {
      setPointsProfYear(0);
    }
  }, [checkProfYearNo, checkProfYearYes, selected, setPointsProfYear]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Professional year in Australia</h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const Naati = ({ setPointsNaati }) => {
  const title =
    "Have you been accredited at the paraprofessional level or above, certified at the certified provisional level or above, or have a community language credential for interpreting or translating by the National Accreditation Authority for Translators and Interpreters?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkNaatiYes, setCheckNaatiYes] = useState(false);
  const [checkNaatiNo, setCheckNaatiNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckNaatiYes(true);
      setCheckNaatiNo(false);
    }
    if (selected === option2) {
      setCheckNaatiYes(false);
      setCheckNaatiNo(true);
    }

    if (checkNaatiYes) {
      setPointsNaati(5);
    }
    if (checkNaatiNo) {
      setPointsNaati(0);
    }
  }, [checkNaatiNo, checkNaatiYes, selected, setPointsNaati]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">
        Credentialled community language (NAATI)
      </h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const PartnerSkills = ({ setPointsPartner }) => {
  const title = "Select the relevant partner skills qualifications.";
  const option1 =
    "Your spouse or de facto partner must also be an applicant for this visa and meet age, English and skill criteria";
  const option2 =
    "Your spouse or de facto partner must also be an applicant for this visa and has competent English";
  const option3 =
    "You are single or your partner is an Australian citizen or permanent resident";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [partnerSkill, setPartnerSkill] = useState(false);
  const [partnerEng, setPartnerEng] = useState(false);
  const [partnerNo, setPartnerNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setPartnerSkill(true);
      setPartnerEng(false);
      setPartnerNo(false);
    }
    if (selected === option2) {
      setPartnerSkill(false);
      setPartnerEng(true);
      setPartnerNo(false);
    }
    if (selected === option3) {
      setPartnerSkill(false);
      setPartnerEng(false);
      setPartnerNo(true);
    }

    if (partnerSkill) {
      setPointsPartner(10);
    }
    if (partnerEng) {
      setPointsPartner(5);
    }
    if (partnerNo) {
      setPointsPartner(10);
    }
  }, [partnerEng, partnerNo, partnerSkill, selected, setPointsPartner]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Partner skills</h1>
      <p className="mb-4">
        {title} <span className="text-red-700 text-xl">*</span>
      </p>

      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <ul className="ml-10 mb-2 text-gray-400">
          <li>were under 45 years old</li>
          <li>had competent English (6 in IELTS or 50 in PTE)</li>
          <li>
            had nominated a skilled occupation that is on the same skilled
            occupation list as your nominated skilled occupation
          </li>
          <li>
            had a suitable skills assessment from the relevant assessing
            authority for their nominated skilled occupation, and the assessment
            wasn't for a Subclass 485 visa.
          </li>
        </ul>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
        <p className="ml-6 mb-2 text-gray-400">
          At least 6 in IELTS (50 in PTE) or equivalent in other approved tests
        </p>

        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option3}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option3}
              onChange={() => handleChange(option3)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const Nomination = ({ setPointsNomination }) => {
  const title =
    "Have you been invited to apply for a Skilled Nominated visa (subclass 190)  and the nominating State or Territory government agency has not withdrawn the nomination?";
  const option1 = "Yes";
  const option2 = "No";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [checkNominationYes, setCheckNominationYes] = useState(false);
  const [checkNominationNo, setCheckNominationNo] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setCheckNominationYes(true);
      setCheckNominationNo(false);
    }
    if (selected === option2) {
      setCheckNominationYes(false);
      setCheckNominationNo(true);
    }

    if (checkNominationYes) {
      setPointsNomination(5);
    }
    if (checkNominationNo) {
      setPointsNomination(0);
    }
  }, [checkNominationNo, checkNominationYes, selected, setPointsNomination]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Nomination</h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const Sponsor491 = ({ setPointsSponsor491 }) => {
  const title = "Have you been nominated or sponsored to apply for this visa?";
  const option1 =
    "You were invited to apply for a Skilled Work Regional (Provisional) visa because you were nominated and the nominating State or Territory government agency has not withdrawn that nomination";
  const option2 =
    "You are being sponsored for a Skilled Work Regional (Provisional) visa by a family member and the Minister has accepted that sponsorship";
  const option3 = "Not Applicable";

  const [selected, setSelected] = useState("None"); // Set an initial value

  const handleChange = (option) => {
    setSelected(option);
  };
  const [nominated, setNominated] = useState(false);
  const [sponsored, setSponsored] = useState(false);
  const [noneApp, setNonApp] = useState(false);

  useEffect(() => {
    if (selected === option1) {
      setNominated(true);
      setSponsored(false);
      setNonApp(false);
    }
    if (selected === option2) {
      setNominated(false);
      setSponsored(true);
      setNonApp(false);
    }
    if (selected === option3) {
      setNominated(false);
      setSponsored(false);
      setNonApp(true);
    }

    if (nominated) {
      setPointsSponsor491(15);
    }
    if (sponsored) {
      setPointsSponsor491(15);
    }
    if (noneApp) {
      setPointsSponsor491(0);
    }
  }, [nominated, noneApp, selected, setPointsSponsor491, sponsored]);

  return (
    <div className="relative">
      <h1 className="text-xl mb-2 font-bold">Nomination or Sponsorship</h1>
      <p className="mb-4">
        {title} <span className="text-red-700">*</span>
      </p>
      <hr />
      <div className="flex flex-col">
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option1}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option1}
              onChange={() => handleChange(option1)}
            />
          </div>
        </label>
        <hr />
        <label className="inline-flex items-center justify-between my-2 gap-2">
          <span className="ml-2">{option2}</span>
          <div>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={selected === option2}
              onChange={() => handleChange(option2)}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export const Result189 = ({
  pointsAge,
  pointsEng,
  pointsOVemp,
  pointsAUemp,
  pointsEdu,
  pointsSepcEdu,
  pointsAusStudy,
  pointsRegStudy,
  pointsProfYear,
  pointsNaati,
  pointsPartner,
}) => {
  const totalPoints =
    0 |
    (pointsAge +
      pointsEng +
      pointsOVemp +
      pointsAUemp +
      pointsEdu +
      pointsSepcEdu +
      pointsAusStudy +
      pointsRegStudy +
      pointsProfYear +
      pointsNaati +
      pointsPartner);

  return (
    <div
      className={`bg-[#3b82f6] w-[300px] rounded-lg shadow-lg shadow-gray-400`}
    >
      <div className="flex text-white flex-col items-center justify-center py-2 text-lg">
        Your total points is <p className="text-2xl">{totalPoints}</p>
      </div>
    </div>
  );
};

export const Result190 = ({
  pointsAge,
  pointsEng,
  pointsOVemp,
  pointsAUemp,
  pointsEdu,
  pointsSepcEdu,
  pointsAusStudy,
  pointsRegStudy,
  pointsProfYear,
  pointsNaati,
  pointsPartner,
  pointsNomination,
}) => {
  const totalPoints =
    0 |
    (pointsAge +
      pointsEng +
      pointsOVemp +
      pointsAUemp +
      pointsEdu +
      pointsSepcEdu +
      pointsAusStudy +
      pointsRegStudy +
      pointsProfYear +
      pointsNaati +
      pointsPartner +
      pointsNomination);

  return (
    <div
      className={`bg-[#3b82f6] w-[300px] rounded-lg shadow-lg shadow-gray-400`}
    >
      <div className="flex text-white flex-col items-center justify-center py-2 text-lg">
        Your total points is <p className="text-2xl">{totalPoints}</p>
      </div>
    </div>
  );
};

export const Result491 = ({
  pointsAge,
  pointsEng,
  pointsOVemp,
  pointsAUemp,
  pointsEdu,
  pointsSepcEdu,
  pointsAusStudy,
  pointsRegStudy,
  pointsProfYear,
  pointsNaati,
  pointsPartner,
  pointsSponsor491,
}) => {
  const totalPoints =
    0 |
    (pointsAge +
      pointsEng +
      pointsOVemp +
      pointsAUemp +
      pointsEdu +
      pointsSepcEdu +
      pointsAusStudy +
      pointsRegStudy +
      pointsProfYear +
      pointsNaati +
      pointsPartner +
      pointsSponsor491);

  return (
    <div
      className={`bg-[#3b82f6] w-[300px] rounded-lg shadow-lg shadow-gray-400`}
    >
      <div className="flex text-white flex-col items-center justify-center py-2 text-lg">
        Your total points is <p className="text-2xl">{totalPoints}</p>
      </div>
    </div>
  );
};
