import "./App.css";
import Footer from "./components/Footer";
import SubClass from "./pages/PRPoints";
import NavBar from "./components/Navbar";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <>
      <ChakraProvider>
        <NavBar />
        <SubClass />
        <Footer />
      </ChakraProvider>
    </>
  );
}

export default App;
